import { render, staticRenderFns } from "./createLatestArrival.vue?vue&type=template&id=58bdfc24&scoped=true&"
import script from "./createLatestArrival.vue?vue&type=script&lang=js&"
export * from "./createLatestArrival.vue?vue&type=script&lang=js&"
import style0 from "./createLatestArrival.vue?vue&type=style&index=0&id=58bdfc24&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "58bdfc24",
  null
  
)

export default component.exports